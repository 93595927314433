var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import PhoneModify from "./phone-query-base/phone-modify.vue";
import RemotePhoneModify from "./phone-query-base/remote-phone-modify.vue";
import PhoneRemarkModify from "./phone-query-base/phone-remark-modify.vue";
import WorkPhoneQuery from "~/components/workspace/work-phone-query.vue";
import { Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { PersonalService } from "~/services/business-service/personal.service";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { AssistCollectionCaseService } from "~/services/domain-service/assist-collection-case.service";
import FollowRecordPhoneSecond from "~/components/collection-manage/execution/follow-record-base/follow-record-phone-second.vue";
import Skill from "~/components/collection-manage/execution/follow-record-base/skill.vue";
import DragDialog from "~/components/common/drag-dialog.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
import { OutboundService } from "~/services/common-service/outbound.service";
import CallConfirm from './call-confirm.vue';
var collectionManageModule = namespace("collection-manage");
var outboundManageModule = namespace("outbound-manage");
var PhoneQuery = /** @class */ (function (_super) {
    __extends(PhoneQuery, _super);
    function PhoneQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentRelation = {};
        _this.dataSet = [];
        _this.selectionList = [];
        _this.selectionTelList = [];
        _this.dialog = {
            remotePhoneModify: false,
            phoneModifyEdit: false,
            phoneRemarkModify: false,
            phoneRecord: false,
            phone: false,
            skill: false,
            callConfirm: false,
            pltel: false,
        };
        _this.loading = {
            state: false,
        };
        _this.phoneNumber = "";
        _this.markIds = [];
        _this.rowData = {};
        _this.companyid = "18"; //企业ID
        _this.caseType = ""; //调解方式显示隐藏
        _this.phoneData = {};
        return _this;
    }
    PhoneQuery.prototype.onRecordCountChange = function () {
        this.refreshData();
    };
    PhoneQuery.prototype.onCaseIdChange = function (val) {
        val && this.refreshData();
    };
    PhoneQuery.prototype.onPersonalIdChange = function (val) {
        val && this.refreshData();
    };
    /**
     * 关闭催记dialog
     */
    PhoneQuery.prototype.phoneRecordClose = function () {
        this.dialog.phoneRecord = false;
        this.phoneData = {};
        this.refreshData();
    };
    PhoneQuery.prototype.refreshData = function () {
        var _this = this;
        this.caseType = this.queryOtherData("caseType");
        if (!this.personalId)
            return;
        this.personalService.findPersonalContact(this.caseId).subscribe(function (data) {
            _this.dataSet = data.map(function (v) { return Object.assign({ recordsDataSet: [] }, v); });
        });
    };
    // 标记未知
    PhoneQuery.prototype.markUnknown = function () {
        this.markCase("UNKNOWN");
    };
    // 标记有效
    PhoneQuery.prototype.markEffective = function () {
        this.markCase("EFFECTIVE");
    };
    // 标记无效
    PhoneQuery.prototype.markInvalid = function () {
        this.markCase("INVALID");
    };
    // 案件标记
    PhoneQuery.prototype.markCase = function (data) {
        var _this = this;
        if (this.selectionList.length == 0) {
            this.$message.info("请选择要标记的联系人");
            return;
        }
        this.markIds = [];
        this.selectionList.forEach(function (x) {
            _this.markIds.push(x.id);
        });
        var params = {
            ids: this.markIds,
            flag: 0,
            status: data,
            caseId: this.caseId,
            caseType: this.otherData.caseType,
        };
        this.personalService.markStatus(params).subscribe(function (data) {
            _this.$message.success("标记成功");
            _this.refreshData();
            _this.selectionList = [];
        });
    };
    /**
     * 坐席登录
     */
    PhoneQuery.prototype.loginSeats = function () {
        var _this = this;
        this.outboundService.agentLogin().subscribe(function (data) {
            _this.$message.success("坐席登陆成功!");
        });
    };
    /**
     * 坐席退出
     */
    PhoneQuery.prototype.logoutSeats = function () {
        var _this = this;
        this.outboundService.agentLogout().subscribe(function (data) {
            _this.$message.success("坐席退出成功!");
        });
    };
    /**
     * 异地协调
     */
    PhoneQuery.prototype.addPlaces = function () {
        // this.currentRelation = data;
        this.dialog.remotePhoneModify = true;
    };
    /**
     * 撤回异地协调
     */
    PhoneQuery.prototype.revokePlaces = function () {
        var _this = this;
        this.$confirm("您确定要撤回异地协调吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.assistCollectionCaseService
                .retractAssistCase({
                caseId: _this.caseId,
                assistContactFlag: "OFFSITE_OUT_ASSIST",
            }, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("异地协调撤回成功");
                _this.caseCollectionSearch(_this.caseId);
            });
        })
            .catch(function () { });
    };
    PhoneQuery.prototype.modify = function (data) {
        this.currentRelation = data;
        this.dialog.phoneModifyEdit = true;
    };
    /**
     * 电话状态改变
     */
    PhoneQuery.prototype.phoneStateChanged = function (record) {
        var phoneStateModel = {
            caseId: this.caseId,
            //联系人ID
            personalContactId: record.id,
            //电话状态
            phoneState: record.phoneState,
            //电话id
            personalPerId: record.personalPerId,
        };
        this.personalService.markPhoneOrAddressStatus(phoneStateModel, this.loading).subscribe(function (data) { }, function (error) { });
    };
    PhoneQuery.prototype.phoneStateDelete = function (id) {
        var _this = this;
        this.$confirm("是否确认删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.personalService.deletePersonalContact(id, 0, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    PhoneQuery.prototype.onRevert = function (rowData) {
        var _this = this;
        this.$confirm("是否确认撤回?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            var assistObj = {
                personalContactId: rowData.id,
                assistContactFlag: rowData.assistContactFlag,
            };
            _this.assistCollectionCaseService.retractAssistCase(assistObj, _this.loading).subscribe(function (data) {
                _this.$message.success("撤回成功!"), _this.refreshData();
            });
            // 撤回操作
        })
            .catch(function () { });
    };
    PhoneQuery.prototype.phoneRemarkModify = function (data) {
        this.currentRelation = data;
        this.dialog.phoneRemarkModify = true;
    };
    PhoneQuery.prototype.onPhoneClick = function () {
        this.selectionTelList = this.selectionList;
        this.dialog.pltel = true;
        console.log(this.selectionTelList);
    };
    PhoneQuery.prototype.removephone = function (val) {
        for (var i = 0; i < this.selectionTelList.length; i++) {
            if (this.selectionTelList[i].id == val.id) {
                this.selectionTelList.splice(i, 1);
            }
        }
    };
    PhoneQuery.prototype.stopTask = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + localStorage.getItem('currtask') + '&action=del&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json4 = httpRequest.responseText;
                console.log(json4);
                that.dialog.pltel = false;
            }
        };
    };
    PhoneQuery.prototype.showPhoneCase = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://pgft.liansdk.com/trusteeship/yalt/getconnect?dst=' + localStorage.getItem('myagent'), true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json4 = httpRequest.responseText;
                console.log(json4);
                if (1 == JSON.parse(json4).code) {
                    console.log('接通的电话：' + JSON.parse(json4).data.src);
                    var phonenum = JSON.parse(json4).data.src;
                    if (phonenum.length < 10) {
                        phonenum = JSON.parse(json4).data.dst;
                    }
                    for (var j = 0; j < that.selectionTelList.length; j++) {
                        console.log('列表的电话：' + that.selectionTelList[j].phoneNo);
                        if (that.selectionTelList[j].phoneNo == phonenum) {
                            alert('接通的联系人：' + that.selectionTelList[j].name);
                            return;
                        }
                    }
                }
                alert('没有接通');
            }
        };
    };
    PhoneQuery.prototype.startTask = function () {
        var that = this;
        var httpRequest3 = new XMLHttpRequest();
        httpRequest3.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + localStorage.getItem('currtask') + '&action=del&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest3.send();
        httpRequest3.onreadystatechange = function () {
            if (httpRequest3.readyState == 4 && httpRequest3.status == 200) {
                that.createTask();
            }
        };
        var httpRequest4 = new XMLHttpRequest();
        httpRequest4.open('GET', 'https://pgft.liansdk.com/trusteeship/yalt/saveaction?agent=' + localStorage.getItem('myagent') + '&action=sanfang', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest4.send();
    };
    PhoneQuery.prototype.createTask = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://legal.liansdk.com/auto_call_api/getAiTaskAdd.php?username=admin&password=Gtkjya2023&company_code=yafz2&template_id=' + localStorage.getItem('mytask') + '&task_name=task_name&task_override=1.5', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json2 = httpRequest.responseText; //获取到json字符串，还需解析
                if (200 == JSON.parse(json2).status) {
                    var taskid = JSON.parse(json2).msg;
                    console.log(taskid);
                    localStorage.setItem('currtask', taskid);
                    var httpRequest2 = new XMLHttpRequest();
                    var formData = new FormData();
                    formData.append('username', 'admin');
                    formData.append('password', 'Gtkjya2023');
                    formData.append('company_code', 'yafz2');
                    formData.append('task_id', taskid);
                    var phonelist = '';
                    for (var i = 0; i < that.selectionTelList.length; i++) {
                        phonelist = phonelist + that.selectionTelList[i].phoneNo + ',';
                    }
                    formData.append('phones', phonelist);
                    httpRequest2.open('POST', 'https://legal.liansdk.com/auto_call_api/postTaskPhones.php', true);
                    httpRequest2.send(formData);
                    httpRequest2.onreadystatechange = function () {
                        if (httpRequest2.readyState == 4 && httpRequest2.status == 200) {
                            var json3 = httpRequest2.responseText;
                            console.log(json3);
                            if (200 == JSON.parse(json3).status) {
                                var httpRequest4 = new XMLHttpRequest();
                                httpRequest4.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + taskid + '&action=run&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
                                httpRequest4.send();
                                httpRequest4.onreadystatechange = function () {
                                    if (httpRequest4.readyState == 4 && httpRequest4.status == 200) {
                                        var json4 = httpRequest4.responseText;
                                        console.log(json4);
                                        alert('呼叫中，请等待接听');
                                    }
                                };
                            }
                        }
                    };
                }
            }
        };
    };
    PhoneQuery.prototype.phoneCall = function (scope) {
        this.rowData = scope.row;
        if (!this.rowData.phoneNo) {
            this.$message.warning('该客户没有手机号!');
            return;
        }
        this.dialog.callConfirm = true;
    };
    /**
     * 拨号
     */
    PhoneQuery.prototype.callOut = function () {
        var _this = this;
        this.outboundService.callOut(this.rowData.phoneNo, this.rowData.id).subscribe(function (data) {
            if (data.code === '200') {
                _this.$message.success(data.msg);
                _this.phoneData = {
                    relation: _this.rowData.relation,
                    name: _this.rowData.name,
                    phoneNo: _this.rowData.phoneNo,
                    id: _this.rowData.id,
                    callId: data.data,
                    phoneType: _this.rowData.type
                };
                _this.dialog.phoneRecord = true;
            }
            else {
                _this.$message.error(data.msg);
            }
        });
    };
    PhoneQuery.prototype.phoneQuery = function (phone) {
        this.phoneNumber = phone;
        this.dialog.phone = true;
    };
    PhoneQuery.prototype.disabledRow = function (row) {
        var _this = this;
        var model = {
            personalPerId: row.personalPerId,
            banState: "DISABLED",
        };
        if (!row.id)
            return;
        this.personalService.modifyBanState(model, this.loading).subscribe(function (data) {
            _this.$message.success("操作成功！");
            _this.refreshData();
        });
    };
    PhoneQuery.prototype.enabledRow = function (row) {
        var _this = this;
        var model = {
            personalPerId: row.personalPerId,
            banState: "ENABLE",
        };
        if (!row.id)
            return;
        this.personalService.modifyBanState(model, this.loading).subscribe(function (data) {
            _this.$message.success("操作成功！");
            _this.refreshData();
        });
    };
    PhoneQuery.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(OutboundService)
    ], PhoneQuery.prototype, "outboundService", void 0);
    __decorate([
        Dependencies(PersonalService)
    ], PhoneQuery.prototype, "personalService", void 0);
    __decorate([
        Dependencies(CaseFollowService)
    ], PhoneQuery.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(AssistCollectionCaseService)
    ], PhoneQuery.prototype, "assistCollectionCaseService", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneQuery.prototype, "recordCount", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PhoneQuery.prototype, "queryOtherData", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneQuery.prototype, "commissionPerson", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneQuery.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.Action
    ], PhoneQuery.prototype, "caseCollectionSearch", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneQuery.prototype, "otherData", void 0);
    __decorate([
        outboundManageModule.Mutation
    ], PhoneQuery.prototype, "updateUUid", void 0);
    __decorate([
        Prop({ required: true })
    ], PhoneQuery.prototype, "caseId", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], PhoneQuery.prototype, "personalId", void 0);
    __decorate([
        Watch("recordCount")
    ], PhoneQuery.prototype, "onRecordCountChange", null);
    __decorate([
        Watch("caseId", { immediate: true })
    ], PhoneQuery.prototype, "onCaseIdChange", null);
    __decorate([
        Watch("personalId", { immediate: true })
    ], PhoneQuery.prototype, "onPersonalIdChange", null);
    PhoneQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                PhoneModify: PhoneModify,
                RemotePhoneModify: RemotePhoneModify,
                PhoneRemarkModify: PhoneRemarkModify,
                FollowRecordPhoneSecond: FollowRecordPhoneSecond,
                DragDialog: DragDialog,
                SvgIcon: SvgIcon,
                WorkPhoneQuery: WorkPhoneQuery,
                Skill: Skill,
                CallConfirm: CallConfirm
            },
        })
    ], PhoneQuery);
    return PhoneQuery;
}(Vue));
export default PhoneQuery;
