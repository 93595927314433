var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { StorageService } from "~/utils/storage.service";
var collectionManageModule = namespace("collection-manage");
var CallConfirm = /** @class */ (function (_super) {
    __extends(CallConfirm, _super);
    function CallConfirm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.flg = false;
        _this.loading = {
            state: false
        };
        return _this;
    }
    CallConfirm.prototype.cancel = function () {
        this.$emit('close');
    };
    CallConfirm.prototype.confrim = function () {
        var that = this;
        this.seatLocalStorage = StorageService.getItem("userInfoStage");
        console.log(this.seatLocalStorage.username);
        var httpRequest = new XMLHttpRequest(); //第一步：建立所需的对象
        httpRequest.open('GET', 'https://pgft.liansdk.com/trusteeship/bj/getagent?username=' + this.seatLocalStorage.username, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send(); //第三步：发送请求  将请求参数写在URL中
        /**
         * 获取数据后的处理程序
         */
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200 && that.flg == false) {
                var json = httpRequest.responseText; //获取到json字符串，还需解析
                console.log(json);
                /**
                 * 获取数据后的处理程序
                 */
                if (JSON.parse(json).code == 1) {
                    httpRequest.open('GET', 'https://legal.liansdk.com/webAPI/webHttpAPI.php?model=OnClickCallHideUniqueid&exten=' + JSON.parse(json).data.agent + '&toTel=' + that.rowData.phoneNo + '&actionId=&userName=yafz2&passWord=75b0465372fa7011d7bf1bf454a7e560641041803f1d77ec23e918f0ff1b209e', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
                    httpRequest.send();
                    httpRequest.onreadystatechange = function () {
                        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                            var json2 = httpRequest.responseText; //获取到json字符串，还需解析
                            console.log(json2);
                            if (JSON.parse(json2).status == true) {
                                console.log('录音code：');
                                console.log(JSON.parse(json2).uniquid);
                                alert('呼叫成功，等待接听');
                            }
                            else {
                                alert('呼叫失败：' + JSON.parse(json2).msg);
                            }
                        }
                    };
                }
                else {
                    alert('获取坐席号失败');
                    that.flg = true;
                    that.$emit('close');
                    return;
                }
                return;
            }
            return;
        };
        that.flg = false;
        return;
        //    this.$emit('close');
        //    this.$emit('callOut')
    };
    __decorate([
        Prop()
    ], CallConfirm.prototype, "rowData", void 0);
    CallConfirm = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem
            }
        })
    ], CallConfirm);
    return CallConfirm;
}(Vue));
export default CallConfirm;
