var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { PaymentRecordService } from "~/services/business-service/payment-record.service";
import { StorageService } from "~/utils/storage.service";
var collectionManageModule = namespace("collection-manage");
var BalanceUpdateQuery = /** @class */ (function (_super) {
    __extends(BalanceUpdateQuery, _super);
    function BalanceUpdateQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.balanceRow = {};
        _this.balanceUpdateDataSet = [];
        _this.loading = {
            state: false
        };
        _this.currentTab = "update";
        _this.dialogFormVisible = false;
        _this.prePayForm = {
            date: '',
            amount: '',
            remark: '',
        };
        _this.formLabelWidth = '120px';
        _this.prePayList = [];
        _this.dialogDeleteVisible = false;
        return _this;
    }
    BalanceUpdateQuery.prototype.onCurrentCaseChanged = function (val) {
        val && this.refreshData();
    };
    BalanceUpdateQuery.prototype.refreshData = function () {
        var _this = this;
        this.paymentRecordService.findPaymentRecord(this.caseId, this.loading).subscribe(function (data) {
            _this.balanceUpdateDataSet = data;
        });
        this.getPrepay();
        this.loading.state = true;
    };
    BalanceUpdateQuery.prototype.balanceRowClick = function (row, event, column) {
        this.balanceRow = row;
    };
    BalanceUpdateQuery.prototype.balanceRowStyle = function (row, index) {
        if (row === this.balanceRow) {
            return { color: "#FF9666" };
        }
    };
    BalanceUpdateQuery.prototype.deletePrepay = function (row) {
        var that = this;
        that.$confirm('此操作将删除该预约还款记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(function () {
            var httpRequest = new XMLHttpRequest();
            httpRequest.open('POST', 'https://pgft.liansdk.com/trusteeship/ft/detRepay?id=' + row.id, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
            httpRequest.send();
            httpRequest.onreadystatechange = function () {
                if (httpRequest.status == 200) {
                    that.refreshData();
                }
                else {
                    that.$message.error("服务器错误，请联系管理员");
                }
            };
        }).catch(function () {
            that.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    };
    BalanceUpdateQuery.prototype.doyy = function () {
        this.dialogFormVisible = true;
    };
    BalanceUpdateQuery.prototype.emityy = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', 'https://pgft.liansdk.com/trusteeship/ft/aptRepay?caseid=' + this.caseId + '&date=' + this.prePayForm.date + '&amount=' + this.prePayForm.amount + "&remark=" + this.prePayForm.remark + "&user=" + StorageService.getItem("userInfoStage").username, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.status == 200) {
                that.dialogFormVisible = false;
                that.refreshData();
            }
            else {
                that.$message.error("服务器错误，请联系管理员");
            }
        };
    };
    BalanceUpdateQuery.prototype.getPrepay = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', 'https://pgft.liansdk.com/trusteeship/ft/getRepay?caseid=' + this.caseId, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.status == 200) {
                var json = httpRequest.responseText;
                console.log(JSON.parse(json));
                if (1 == JSON.parse(json).code) {
                    that.prePayList = JSON.parse(json).data;
                }
            }
            else {
                that.$message.error("服务器错误，请联系管理员");
            }
        };
    };
    BalanceUpdateQuery.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PaymentRecordService)
    ], BalanceUpdateQuery.prototype, "paymentRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], BalanceUpdateQuery.prototype, "caseId", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], BalanceUpdateQuery.prototype, "onCurrentCaseChanged", null);
    BalanceUpdateQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], BalanceUpdateQuery);
    return BalanceUpdateQuery;
}(Vue));
export default BalanceUpdateQuery;
